
export const NAV_PATHS = [
    // {
    //     key: "ticket",
    //     target: "",
    //     url: "/ticket"
    // },
    // {
    //     auth_required: true,
    //     key: "dashboard",
    //     target: "",
    //     url: "/auth/dashboard"
    // },
    {
        auth_required: true,
        key: "manager",
        target: "",
        url: "/auth/manager"
    },
    {
        auth_required: true,
        key: "corporation",
        target: "",
        url: "/auth/corporation"
    },
    // {
    //     auth_required: true,
    //     key: "archive",
    //     target: "",
    //     url: "/auth/archive"
    // },
    {
        auth_required: false,
        key: "about_us",
        target: "_blank",
        url: "https://www.exorint.com/contact-us"
    },
]


export const STATUS_INITIAL = "INITIAL"
export const STATUS_AUTHORIZATION = "authorization"
export const STATUS_UNAUTHORIZATION = "unauthorization"
export const STATUS_LOADING = "loading"

export const STATUS_RMA_CENTER_DEFAULT_DRAFT = "DRAFT"
export const STATUS_RMA_CENTER_DEFAULT_NEW = "NEW"
export const STATUS_RMA_CENTER_DEFAULT_SHIPPED = "SHIPPED"

export const STATUS_RMA_CENTER_DEFAULT_NOT_YET_RECEIVED = "NOT YET RECEIVED"
export const STATUS_RMA_CENTER_DEFAULT_UNDER_TEST_ANALYSIS = "UNDER TEST/ANALYSIS"
export const STATUS_RMA_CENTER_DEFAULT_TO_BE_QUOTED = "TO BE QUOTED"
export const STATUS_RMA_CENTER_DEFAULT_WAITING_FOR_CUSTOMER_APPROVAL = "WAITING FOR CUSTOMER APPROVAL"
export const STATUS_RMA_CENTER_DEFAULT_TO_BE_REPAIRED = "TO BE REPAIRED"
export const STATUS_RMA_CENTER_DEFAULT_RNR = "R.N.R."
export const STATUS_RMA_CENTER_DEFAULT_TO_BE_SHIPPED = "TO BE SHIPPED"


// ***** VAR CONFIG *****
export const KEY_DEFAULT_CONFIG_PAGINATION_LIMIT = "_config_pagination"
export const KEY_LOCAL_STORAGE = "_config_language_local"
export const KEY_HEADER_TOKEN = "authorization"
export const REACT_APP_RECAPTCHA_SITE_KEY = "6Lc_g_wpAAAAAB9Lq7Llan6_eTn3TYwi18gdlyHw"

// export const REACT_APP_KEYCLOAK_URL = "https://auth.corvina.cloud/auth/"
//  *** PRODUCTION ENV ***
export const REACT_APP_KEYCLOAK_URL = "https://auth.corvina.io/auth/"
export const REACT_APP_REALM = "ExorAccounts"
export const REACT_APP_CLIENT_ID = "exor-rma"
export const REACT_APP_URL_API = "https://repair.exorint.com/api"


//  *** TEST ENV ***
// export const REACT_APP_REALM = "ExorAccounts"
// export const REACT_APP_CLIENT_ID = "exor-rma"
// export const REACT_APP_KEYCLOAK_URL = "http://10.1.32.126:8080"
// export const REACT_APP_URL_API = "http://10.1.32.126:4000/api"